<template>
  <v-container v-if="!isFetching" class="pa-0" grid-list-md>
    <v-layout row wrap>
      <v-flex :xs8="$vuetify.breakpoint.mdAndUp" :xs12="$vuetify.breakpoint.smAndDown">
        <v-card id="messages" class="white chat-container" ref="chatContainer" xs12 height="450" v-if="conversations.length > 0 && !error && (paymentStatus === 'Payment Verified' || paymentStatus === 'Completed' || legalAdvice.status === 'Cancelled')">
          <div
            v-for="(convo, index) in conversations" :key="index"
            :class="convo.user._id != $store.getters['user/user'].user._id ? 'message person-1 text-wrap' : 'message person-2 text-wrap'"
          >
            <v-hover v-if="!convo.isFile">
              <v-tooltip
                :left="convo.user._id === $store.getters['user/user'].user._id"
                :right="convo.user._id != $store.getters['user/user'].user._id"
              >
                <template v-slot:activator="{ on }">
                <v-card-actions v-if="!convo.isFile" class="align-start pa-0">
                  <v-avatar
                    v-if="convo.user._id != $store.getters['user/user'].user._id"
                    :size="28"
                    class="mr-2"
                    color="grey"
                  ><img :src="convo.user.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'" alt="avatar">
                  </v-avatar>
                  <v-card-text v-on="on" :class="convo.user._id != $store.getters['user/user'].user._id ? 'primary white--text messageBox px-2 pb-2 pt-1' : 'grey lighten-3 messageBox px-2 pb-2 pt-1'">
                    <p v-for="line in convo.message.split('\n')" :key="line.key" class="caption pt-1 mb-0">{{ line }}</p>
                  </v-card-text>
                </v-card-actions>
                <v-row
                  v-else
                  :justify="convo.user._id != $store.getters['user/user'].user._id ? 'start' : 'end'"
                  no-gutters
                >
                  <v-avatar
                    v-if="convo.user._id != $store.getters['user/user'].user._id"
                    :size="28"
                    class="mr-2"
                    color="grey"
                  ><img :src="convo.user.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'" alt="avatar">
                  </v-avatar>
                  <v-img
                    @click="openGallery(convo.message)" 
                    :src="convo.message"
                    class="messageBox"
                    max-width="80%"
                    max-height="124"
                  >
                  </v-img>
                </v-row>
                </template>
                <p class="mb-0" style="font-size: 10px;">{{ convo.createdAt | dateToWords }}</p>
              </v-tooltip>
            </v-hover>

            <!-- Message Image Area -->
            <v-row
              v-if="convo.isFile"
              class="px-5 py-1"
              :justify="convo.user._id != $store.getters['user/user'].user._id ? 'start' : 'end'"
              no-gutters
            >
              <v-avatar
                v-if="convo.user._id != $store.getters['user/user'].user._id"
                :size="28"
                class="mr-2"
                color="grey"
              ><img :src="convo.user.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'" alt="avatar">
              </v-avatar>

              <!-- <v-card 
                @click="dialog = true, selectedImage = convo.message" 
                width="150px" 
                flat
              > -->
              <v-card 
                @click="openGallery(convo.message)" 
                width="150px" 
                flat
              >
                <v-col cols="12" class="pa-0">
                  <v-img
                    :src="convo.message"
                    aspect-ratio="1"
                  >
                  </v-img>
                </v-col>
              </v-card>
            </v-row>
            <!-- Message Image Area -->
          </div>
        </v-card>
        <v-card id="messages" v-if="conversations.length === 0 && !error && paymentStatus === 'Payment Verified'" class="dropShadow" height="450">
          <v-row align="center" justify="center" class="py-5">
            <v-container class="py-5"> 
              <v-col cols="12" xs12 class="text-center">
                <img class="mx-2" width="200" src="@/assets/NoMessages.png"/>
              </v-col>
              <v-col cols="12" class="text-center pt-2">
                <span class="text-center grey--text font-weight-medium subheading">No Messages Yet</span>
              </v-col>
            </v-container>
          </v-row>
        </v-card>
        <v-sheet id="messages" v-if="error" class="dropShadow" height="450">
          <v-layout row wrap justify-content-center py-5>
            <v-container>
              <v-flex xs12 class="text-xs-center">
                <v-icon x-large>refresh</v-icon>
              </v-flex>
              <v-flex xs12 class="text-xs-center">
                <span class="text-center error--text font-weight-medium subheading">Something went wrong! </span>
                <span class="text-center error--text font-weight-medium subheading">Please try reloading the page.</span>
              </v-flex>
            </v-container>
          </v-layout>
        </v-sheet>
        <MessageArea v-if="!isFinish && paymentStatus === 'Payment Verified'" />
        <v-flex v-if="!isFetching">
          <v-sheet
            id="messages"
            v-if="$store.getters['legalAdvice/legalAdvice'].status === 'Pending Payment' &&
            !$store.getters['legalAdvice/legalAdvice'].isFinish" 
            class="primary pa-3"
          >
            <v-card-actions v-if="$store.getters['user/user'].user.isLawyer" class="pa-0">
              <v-icon class="pr-2" color="white">error</v-icon>
              <span class="white--text">You cannot give an advice yet unless the client deposits the amount.</span>
            </v-card-actions>
            <v-card-actions v-else class="pa-0">
              <v-icon class="pr-2" color="white">error</v-icon>
              <span class="white--text">You have not confirmed your payment. Confirm now so that the lawyer can start providing legal help.</span>
            </v-card-actions>
          </v-sheet>
        </v-flex>
      </v-flex>

      <v-flex :xs4="$vuetify.breakpoint.mdAndUp" :xs12="$vuetify.breakpoint.smAndDown">
        <Documents />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Documents from './Documents'
import MessageArea from '@/components/BoardComponents/NewMessageArea'
import io from 'socket.io-client'
import moment from 'moment'

export default {
  name: 'DocumentConversations',
  components: {
    MessageArea,
    Documents
  },
  props: {
    isFetching: {
      type: Boolean
    },
    isError: {
      type: Boolean
    },
    isFinish: {
      type: Boolean
    },
    paymentStatus: {
      type: String
    }
  },
  data () {
    return {
      dialog: false,
      isDeleting: false,
      error: false,
      on: '',
      selectedImage: '',
      socket: io(process.env.VUE_APP_SERVER),
    }
  },
  methods: {
    downloadFile (fileUrl) {
      window.location.href = fileUrl
    },
    scrollToBottom () {
      let area = this.$el.querySelector("#messages")
      area.scrollTop = area.scrollHeight
    },
    openGallery (imgSrc) {
      this.$emit('openGallery', imgSrc)
    }
  },
  updated () {
    this.scrollToBottom()
  },
  computed: {
    conversations () {
      return this.$store.getters['legalAdvice/conversations']
    },
    legalAdvice () {
      return this.$store.getters['legalAdvice/legalAdvice']
    }
  },
  filters: {
    dateToWords: function (value) {
      return value ? moment(value).format('MMMM DD YYYY, hh:mm A') : ''
    }
  }
}
</script>

<style scoped>
  .message {
    padding: 0px;
    margin: 16px 6px 0;
    max-width: 80%;
    border-radius: 5px;
  }
  .person-1 {
    float: left;
    clear: both;
  }
  .person-2 {
    float: right;
    clear: both;
  }
  .person-1 + .person-1,
  .person-2 + .person-2 {
    margin: 3px 8px 0;
  }
  .messageBox {
    border-radius: 12px;
  }
  .scroll {
    overflow-y: auto;
    padding-top: 2%;
    overflow-x: hidden;
    scroll-behavior: auto;
    scroll-behavior: smooth;
  }

  .chat-container{
    padding-bottom: 12px;
    height: calc(100vh - 10rem);
    overflow-y: auto;
    background-color: #f2f2f2;
  }
</style>