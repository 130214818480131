<template>
  <div>
    <v-dialog persistent v-model="show" width="800">
        <v-card class="pa-3">
          <v-row align="center" class="pt-2">
            <v-flex class="pb-2">
              <div class="px-4">
                <p class="mb-1 subheading font-weight-bold">Are you satisfied with your lawyer?</p>
                <span class="body-1">
                  Help {{ lawyerName }} land his/her next task. Your review will provide a better perspective for other clients to know the lawyer’s
                  capacity to handle their problems. Ensure best service from our lawyers and let others be confident in hiring
                  good lawyers by giving a detailed review.
                </span>
              </div>
            </v-flex>
            <v-divider></v-divider>
            <v-flex class="pt-2 px-4">
              <span class="pl-1">Your Rating: {{ review.rating }} star{{ review.rating > 1 ? 's' : '' }}</span>
              <v-rating
                dense
                empty-icon="star"
                background-color="grey"
                color="yellow accent-4"
                v-model="review.rating"
              ></v-rating>
            </v-flex>
            <v-flex xs12 class="pt-2 px-4">
              <v-form ref="rateCommentForm">
                <v-textarea
                  outlined
                  label="Type your review here ..."
                  counter="1000"
                  :rules="ratingCommentRules"
                  v-model="review.comment"
                ></v-textarea>
              </v-form>
            </v-flex>
          </v-row>
          <v-card-actions v-if="$vuetify.breakpoint.mdAndUp" class="px-4 pb-4">
            <v-btn
              large
              depressed
              text
              dark
              color="grey"
              @click="$emit('close'), review.rating = 3, $refs.rateCommentForm.reset()"
            >Cancel</v-btn>
            <Dispute :visible="disputeModal" 
              :lawyerId="lawyerId" 
              :clientId="clientId" 
              :problemId="problemId" 
              :problemTitle="problemTitle"
              :boardId="$route.params.boardId" 
              :lawyerEmail="lawyerEmail"
              :isAnonymous="isAnonymous"
              @close="disputeModal = false"/>
            <v-spacer></v-spacer>
            <!-- <v-btn
              large
              depressed
              text
              color="error"
              :loading="isLoading"
              v-if="amount > 0"
              @click="disputeModal = true"
            >I want to dispute</v-btn> -->
            <v-btn
              large
              depressed
              class="primary"
              :loading="isLoading"
              @click="rateLawyer()"
            >Rate and Complete Task</v-btn>
          </v-card-actions>
          <v-flex v-else xs12 px-4 pb-3>
            <v-btn
              block
              depressed
              color="primary"
              :loading="isLoading"
              @click="rateLawyer()"
            >Rate and Complete Task</v-btn>
            <v-btn
              block
              depressed
              text
              dark
              color="grey"
              @click="$emit('close'), review.rating = 3, $refs.rateCommentForm.reset()"
            >Cancel</v-btn>
          </v-flex>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import io from 'socket.io-client'
import Dispute from '@/components/BoardComponents/Dispute'
import BoardService from '@/services/BoardService'

export default {
  name: 'RateLawyer',
  components: {
    Dispute
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    lawyerName: {
      required: true,
      type: String
    },
    problemId: {
      required: true,
      type: String
    },
    clientId: {
      required: true,
      type: String
    },
    lawyerId: {
      required: true,
      type: String
    },
    problemTitle: {
      required: true,
      type: String
    },
    lawyerEmail: {
      required: true,
      type: String
    },
    amount: {
      required: true,
      type: Number
    },
    isAnonymous: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      disputeModal: false,
      isLoading: false,
      adviceId: '',
      ratingComment: '',
      review: {
        rating: 3,
        comment: ''
      },
      ratingCommentRules: [
        (v) => !!v || 'Please enter your comment.',
        (v) => (v && v.length >= 30) || 'Please enter at least 30 characters.',
        (v) => (v && v.length <= 1000) || 'Please enter below 1000 characters.'
      ]
    }
  },
  methods: {
    rateLawyer () {
      if (this.$refs.rateCommentForm.validate()) {
        this.isLoading = true
        BoardService.completeJob({
          boardId: this.$route.params.boardId,
          lawyerId: this.lawyerId,
          lawyerName: this.lawyerName,
          legalAdviceAmount: this.amount,
          problemTitle: this.problemTitle,
          problemId: this.problemId,
          review: this.review,
          feature: 'Legal Advice'
        })
          .then(res => {
            this.$store.dispatch('legalAdvice/completeLegalAdvice')
            this.socket.emit('complete-job', {
              notif: 1,
              notifType: 'Advice Completed',
              email: this.lawyerEmail,
              problem: {
                _id: this.problemId,
                isAnonymous: this.isAnonymous
              },
              transaction: {
                amount: this.amount,
                type: 'Legal Advice'
              },
              client: {
                fullName: this.$store.getters['user/user'].user.fullName,
                imageUrl: this.$store.getters['user/user'].user.imageUrl
              }
            })
            this.$swal({
              type: 'success',
              text: 'Successfully completed the job.',
              confirmButtonColor: this.$vuetify.theme.primary,
              onOpen: () => { document.activeElement.blur() }
            })
          })
          .catch(() => {
            this.$swal({
              type: 'error',
              text: 'Something went wrong with the server!',
              confirmButtonColor: this.$vuetify.theme.primary,
              onOpen: () => { document.activeElement.blur() }
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
  },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>

<style scoped>
  .card {
    overflow-x: hidden;
  }
</style>
