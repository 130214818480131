<template>
  <v-col v-if="!isFetching" cols="12" class="pa-0">
    <v-card :style="$vuetify.breakpoint.smAndDown ? 'position: fixed; bottom: 0; width: 100%; border-radius: 0px; overflow-x: hidden;' : 'overflow-x: hidden;'" class="pa-0" flat>
      <v-row v-if="!$vuetify.breakpoint.mdAndDown" align="end" class="py-1" no-gutters>
        <v-col cols="1" class="text-center pb-3">
          <v-icon @click="dialog = true, isFile = true, message = 'Sending file..'">mdi-image</v-icon>
        </v-col>
        <v-col 
          cols="10" 
          md="10" 
          :class="{ 
            'text-right py-1 pl-0': $vuetify.breakpoint.mdAndUp,
            'text-right py-1': $vuetify.breakpoint.smAndDown }">
          <v-form ref="form">
            <v-textarea 
              solo
              flat
              auto-grow 
              dense
              hide-details
              full-width
              background-color="bgApp"
              label="Type a Message..."
              rows="1"
              ref="messageconvo"
              :disabled="isLoading"
              v-model="message"
              @keydown.enter.exact.prevent="sendMessage()"
              class="my-input"
              style="font-size: 12px; line-height: 10px;"
            >
              <template v-slot:label>
                <p class="caption">Type a Message...</p>
              </template>
            </v-textarea>
          </v-form>
        </v-col>
        <v-col cols="1" md="1" class="text-center pb-3">
          <v-icon @click="sendMessage()" v-if="!isLoading" color="primary">send</v-icon>
          <v-progress-circular
            v-else
            indeterminate
            width="3"
            size="24"
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-else align="end" class="py-1" no-gutters>
        <v-col cols="12">
        <v-card-actions class="align-end">
          <v-icon class="pl-1 pr-3 pb-2" @click="dialog = true, isFile = true, message = 'Sending file..'">mdi-image</v-icon>
          <v-textarea 
            solo
            flat
            auto-grow 
            dense
            block
            hide-details
            full-width
            background-color="bgApp"
            label="Type a Message..."
            rows="1"
            ref="messageconvo"
            :disabled="isLoading"
            v-model="message"
            @keydown.enter.exact.prevent="sendMessage()"
            class="my-input"
            style="font-size: 12px; line-height: 10px;"
          >
            <template v-slot:label>
              <p class="caption">Type a Message...</p>
            </template>
          </v-textarea>
          <div class="pl-3 pb-2">
            <v-icon @click="sendMessage()" v-if="!isLoading" color="primary">send</v-icon>
            <v-progress-circular
              v-else
              indeterminate
              width="3"
              size="24"
              color="primary"
            ></v-progress-circular>
          </div>
        </v-card-actions>
        </v-col>
        <!-- <v-col cols="1" class="text-center pb-3">
          <v-icon @click="dialog = true, isFile = true, message = 'Sending file..'">mdi-image</v-icon>
        </v-col>
        <v-col 
          cols="9" 
          md="9" 
          :class="{ 
            'text-right py-1 pl-0': $vuetify.breakpoint.mdAndUp,
            'text-right py-1': $vuetify.breakpoint.smAndDown }">
          <v-form ref="form">
            <v-textarea 
              solo
              flat
              auto-grow 
              dense
              hide-details
              full-width
              background-color="bgApp"
              label="Type a Message..."
              rows="1"
              ref="messageconvo"
              :disabled="isLoading"
              v-model="message"
              @keydown.enter.exact.prevent="sendMessage()"
              class="my-input"
              style="font-size: 12px; line-height: 10px;"
            >
              <template v-slot:label>
                <p class="caption">Type a Message...</p>
              </template>
            </v-textarea>
          </v-form>
        </v-col>
        <v-col cols="1" md="1" class="text-center pb-3">
          <v-icon @click="sendMessage()" v-if="!isLoading" color="primary">send</v-icon>
          <v-progress-circular
            v-else
            indeterminate
            width="3"
            size="24"
            color="primary"
          ></v-progress-circular>
        </v-col> -->
      </v-row>
    </v-card>
    <v-dialog v-model="dialog" width="300" persistent>
      <v-card class="pa-3">
        <v-row align="center" no-gutters>

          <v-col cols="12">
            <v-form ref="fileForm">
              <v-file-input 
                :rules="fileSizeRule"
                v-model="file"
                prepend-icon="" 
                append-icon="mdi-paperclip" 
                accept="image/*, text/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                outlined 
                dense 
                multiple
                hide-details
              >
                <template v-slot:label>
                  <span class="body-2">Attach file/photo</span>
                </template>
              </v-file-input>
            </v-form>
            
          </v-col>

          <v-col cols="12" class="py-2">
            <v-btn :loading="isLoading" color="primary" @click="sendMessage()" depressed dense block>Send</v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn :disabled="isDisabled" @click="dialog = false, isFile = false, message = '', file = [], $refs.fileForm.reset()" depressed dense block text>Cancel</v-btn>
          </v-col>

        </v-row>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import io from 'socket.io-client'
import rulesMixin from '@/mixins/rulesMixin'

export default {
  name: 'ConvoMessage',
  props: {
    isFetching: {
      type: Boolean
    }
  },
  mixins: [ rulesMixin ],
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      isDisabled: false,
      dialog: false,
      isFile: false,
      isLoading: false,
      file: [],
      fileSizeRule: [ (v) => v.length > 0 || 'Image is required.' ],
      imgFormats: [
        { image: '.jpg' },
        { image: '.png' },
        { image: '.jpeg' },
        { image: '.JPG' },
        { image: '.JPEG' },
        { image: '.PNG' },
      ],
      textFileFormat: [
        { text: '.txt' },
        { text: '.docx' },
        { text: '.doc' },
      ],
      message: ''
    }
  },
  methods: {
    imageFile (value) {
      for (let i = 0; i < this.imgFormats.length; i++) {
        if (value.includes(this.imgFormats[i].image)) {
          return true
        }
      }
      for (let i = 0; i < this.textFileFormat.length; i++) {
        if (value.includes(this.textFileFormat[i].text)) {
          return false
        }
      }
      if (value.includes('.pdf')) {
        return false
      }
    },
    messageRestrictions (value) {
      let stringArray = value.split(" ")
      for (let i = 0; i < stringArray.length; i++) {
        if (stringArray[i].substring(0, 2).includes('09') || stringArray[i].substring(0, 3).includes('+63') || value.includes('@')) {
          return stringArray[i].substring(0, 2).includes('09') || stringArray[i].substring(0, 3).includes('+63') || value.includes('@') 
        }
      }
    },
    sendMessage () {
      if ((this.isFile && this.imageFile(this.file[0].name)) || !this.isFile) {
         if (/\S/.test(this.message) && !this.messageRestrictions(this.message)) {
          let formData = new FormData()
          formData.append('isFile', this.isFile)
          formData.append('file', this.file[0])
          formData.append('message', this.message)
          this.isLoading = true
          this.isDisabled = true
          this.$store.dispatch('legalAdvice/sendMessage', { 
            formData, 
            adviceId: this.$route.params.boardId
          })
            .then(res => {
              this.socket.emit('reply', {
                _id: res.data[res.data.length - 1]._id,
                boardId: this.$route.params.boardId,
                user: {
                  _id: this.$store.getters['user/user'].user._id,
                  imageUrl: this.$store.getters['user/user'].user.imageUrl
                },
                message: this.isFile ? res.data[res.data.length - 1].message : this.message,
                isFile: this.isFile,
                createdAt: new Date()
              })
              this.file = []
            })
            .catch(err => {
              this.$swal({
                type: 'error',
                text: 'Something went wrong with the server.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .finally(() => {
              this.dialog = false
              this.isFile = false
              this.isDisabled = false
              this.isLoading = false
              this.message = ''
              this.$nextTick(() => this.$refs.messageconvo.focus())
            })
        } 
        else if (!/\S/.test(this.message)) {
          this.message = ''
        }
        else if (this.messageRestrictions(this.message)) {
          this.message = ''
          this.$swal({
            type: 'error',
            text: 'You are not allowed to disclose email or phone number.'
          })
        }
      } 
      else if (this.isFile && !this.imageFile(this.file[0].name)) {
        this.isLoading = true
        this.isDisabled = true
        let formData = new FormData()
        formData.append('file', this.file[0])
        this.$store.dispatch('legalAdvice/sendDocument', { adviceId: this.$route.params.boardId, formData })
          .then(res => {
            this.socket.emit('send-file', {
              boardId: this.$route.params.boardId,
              _id: res.data[0]._id,
              fileName: res.data[0].fileName,
              fileUrl: res.data[0].fileUrl,
              createdAt: new Date()
            })
          })
          .catch(err => {
            this.$swal({
              type: 'error',
              text: 'Something went wrong with the server!',
              confirmButtonColor: this.$vuetify.theme.primary,
              onOpen: () => { document.activeElement.blur() }
            })
          })
          .finally(() => {
            this.isFile = false
            this.dialog = false
            this.isLoading = false
            this.isDisabled = false
            this.message = ''
            this.file = []
          })
      }
    }
  },
  computed: {
    conversations () {
      return this.$store.getters['legalAdvice/conversations']
    }
  }
}
</script>

<style scoped>
  >>>.v-textarea textarea {
    padding-bottom: -12px;
    line-height: 20px;
  }
  .messageDropShadow {
    box-shadow: 0px 2px 5px 0px hsla(0, 0%, 0%, 0.3)
  }
</style>