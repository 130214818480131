<template>
  <v-row tabindex="0" >
    <v-dialog v-model="openGallery" @keydown.esc="$emit('close')" @keydown.arrow-right="next()" @keydown.arrow-left="prev()" justify="center" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card style="background: rgba(0, 0, 0, 0.9);" tile>
        <v-hover v-if="!$vuetify.breakpoint.mdAndDown" v-slot:default="{ hover }">
          <v-layout style="height: 84vh;" align-center justify-center wrap>
            <transition name="fade">
              <v-app-bar
                v-show="hover"
                color="transparent"
                style="box-shadow: 0 0 150px inset rgba(0, 0, 0, 0.8); background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, transparent 60px);"
                flat
                absolute
                dark
              >
                <v-spacer></v-spacer>
                <v-btn @click="downloadFile()" text>
                  <v-icon class="pr-1">mdi-download</v-icon>
                  <p class="mb-0 caption">Download</p>
                </v-btn>
                <v-btn @click="$emit('close')" icon>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-app-bar>
            </transition>
            <v-flex xs3>
              <v-btn v-if="selectedIndex != 0" @click="prev()" dark icon large>
                <v-icon x-large>mdi-chevron-left</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs6>
              <v-img :src="imgs[selectedIndex]" contain max-height="84vh"></v-img>
            </v-flex>
            <v-flex xs3 class="text-right">
              <v-btn v-if="selectedIndex + 1 < imgs.length" @click="next()" dark icon large >
                <v-icon x-large>mdi-chevron-right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-hover>
        <v-layout v-else style="height: 100vh;" align-center justify-center wrap>
          <v-app-bar
            color="transparent"
            style="box-shadow: 0 0 150px inset rgba(0, 0, 0, 0.8); background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, transparent 60px);"
            flat
            absolute
            dark
          >
            <v-btn @click="$emit('close')" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="downloadFile()" text>
              <v-icon class="pr-1">mdi-download</v-icon>
              <p class="mb-0 caption">Download</p>
            </v-btn>
          </v-app-bar>
          <v-flex xs12>
            <v-carousel
              :show-arrows="false"
              :continuous="false"
              :value="selectedIndex"
              hide-delimiters
            >
              <v-carousel-item
                v-for="(img, i) in imgs"
                :key="i"
                :src="img"
                contain
              >
              </v-carousel-item>
            </v-carousel>
          </v-flex>
        </v-layout>
        <v-flex v-if="!$vuetify.breakpoint.mdAndDown" xs12 class="py-3">
          <v-divider color="grey"></v-divider>
        </v-flex>
        <v-flex v-if="!$vuetify.breakpoint.mdAndDown" xs12 class="px-1 text-right">
          <v-slide-group dark show-arrows>
            <v-slide-item
              v-for="(img, index) in imgs"
              :key="index"
            >
              <v-card height="80" width="80" class="mx-1" tile>
                <v-img @click="viewImage(index)" :src="img" :aspect-ratio="1" class="thumbnails">
                  <v-row
                    v-if="index != selectedIndex"
                    class="fill-height"
                    style="background-color: rgba(0, 0, 0, 0.6);">
                  </v-row>
                </v-img>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-flex>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    openGallery: {
      type: Boolean,
      required: true
    },
    imageSrc :{
      type: String
    }
  },
  data () {
    return {
      notifications: false,
      sound: true,
      widgets: false,
      selectedIndex: 0
    }
  },
  methods: {
    initIndex () {
      for (let i = 0; i < this.imgs.length; i++) {
        if (this.imgs[i] == this.imageSrc) {
          this.selectedIndex = i
        }
      }
    },
    viewImage (index) {
      this.selectedIndex = index
    },
    next () {
      if (this.selectedIndex + 1 < this.imgs.length) {
        this.selectedIndex++
      }
    },
    prev () {
      if (this.selectedIndex != 0) {
        this.selectedIndex--
      }
    },
    downloadFile () {
      window.location.href = this.imgs[this.selectedIndex]
    }
  },
  computed: {
    imgs () {
      return this.$store.getters['legalAdvice/conversationImages']
    }
  },
  watch: {
    imageSrc () {
      this.initIndex()
    }
  }
}
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .thumbnails:hover {
    cursor: pointer;
  }
</style>