<template>
  <v-dialog persistent v-model="show" max-width="700px">
    <v-sheet>
      <v-layout row wrap align-center class="primary">
        <v-flex v-if="$vuetify.breakpoint.mdAndUp" class="text-center white--text">
          <p>To Pay</p>
          <p class="display-2">{{ amount | toLocaleString }}</p>
          <v-spacer></v-spacer>
          <v-btn
            large
            depressed
            @click="show = false, imgSrc = '', file = '', fileName = '', resetImage()"
          >Cancel</v-btn>
        </v-flex>
        <v-flex xs12 md8 class="pa-4" style="background-color: white;">
          <input
            ref="image"
            type="file"
            name="image"
            accept="image/*"
            style="display: none;"
            @change="setImage"
          />
          <v-layout row wrap align-center>
            <v-flex xs12 pt-3>
              <p class="body-2 text-center grey--text">My Funds</p>
            </v-flex>
            <v-flex xs12 class="text-center px-5">
              <v-flex xs12 text-xs-center pb-3>
                <span class="display-1">{{ wallet | toLocaleString }}</span>
              </v-flex>
              <v-btn
                :disabled="wallet < amount"
                large
                depressed
                block
                color="success"
                :loading="useFundsLoading"
                @click="useFunds()"
              >Use Funds</v-btn>
            </v-flex>
            <v-flex xs12 px-5 class="py-3"><v-divider></v-divider></v-flex>
            <v-flex xs12 class="px-5">
              <p class="body-2 text-center grey--text">or upload your deposit slip</p>
              <VueCropper
                v-show="false"
                ref="cropper"
                :dragMode="'none'"
                :viewMode="1"
                :autoCrop="false"
                :zoomOnWheel="false"
                :background="false"
                :src="imgSrc"
              />
              <v-form lazy-validation class="pb-3 text-xs-center" ref="addImageForm">
                <v-text-field
                  outlined
                  hide-details
                  :label="imgSrc ? 'Click here to replace the image' : 'Click here to upload the image'"
                  append-icon='attach_file'
                  :rules="imageRule"
                  v-model="fileName"
                  @click='launchFilePicker'
                ></v-text-field>
                <v-btn
                  v-if="imgSrc"
                  depressed
                  large
                  block
                  color="primary"
                  :loading="isUploading"
                  @click="uploadReceipt"
                >Upload Receipt</v-btn>
              </v-form>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex v-if="!$vuetify.breakpoint.mdAndUp" py-4 xs12 text-center white--text>
          <p>To Pay</p>
          <p class="display-1">{{ amount | toLocaleString }}</p>
          <v-spacer></v-spacer>
          <v-btn
            large
            depressed
            @click="show = false, imgSrc = '', file = '', fileName = ''"
          >Cancel</v-btn>
        </v-flex>
      </v-layout>
    </v-sheet>
  </v-dialog>
</template>

<script>
import io from 'socket.io-client'
import toLocaleString from '@/mixins/toLocaleStringMixin'

export default {
  name: 'BoardUploadPayment',
  mixins: [
    toLocaleString
  ],
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    amount: {
      type: Number,
      required: true
    },
    boardId: {
      type: String,
      required: true
    },
    service: {
      type: String,
      required: true
    },
    lawyerId: {
      type: String,
      required: true
    },
    lawyerEmail: {
      type: String,
      required: true
    },
    clientName: {
      type: String,
      required: true
    },
    problem: {
      type: String,
      required: true
    },
    isAnonymous: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      imgSrc: '',
      file: '',
      fileName: '',
      type: 'Add Funds',
      count: 1,
      useFundsLoading: false,
      isUploading: false,
      imageRule: [
        (v) => !!v || 'Please enter an image.'
      ]
    }
  },
  methods: {
    launchFilePicker () {
      this.$refs.image.click()
    },
    setImage (e) {
      if (e.target.files[0]) {
        this.file = e.target.files[0]
      }

      if (this.file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        this.imgSrc = ''
        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          this.fileName = e.target.files[0].name
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(this.file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    uploadReceipt () {
      if (this.$refs.addImageForm.validate()) {
        this.isUploading = true

        this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
          const formData = new FormData()
          formData.append('receipt', blob)
          formData.append('user', this.$store.getters['user/user'].user._id)
          formData.append('type', 'Legal Advice Payment')
          formData.append('amount', this.amount)
          formData.append('status', 'Pending')
          formData.append('fromBoard', true)
          formData.append('boardId', this.boardId)

          this.$store.dispatch('board/ADD_PAYMENT_IN_BOARD', { formData, service: this.service })
            .then(res => {
              this.socket.emit('addfunds', {
                type: this.type,
                count: this.count
              })
              this.success()
              this.$swal({
                type: 'success',
                text: 'Image uploaded, please wait for us to verify your payment.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .catch(err => {
              this.$swal({
                type: 'error',
                text: err,
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .finally(() => {
              this.isUploading = false
            })
        }, this.file.type, 0.3)
      }
    },
    useFunds () {
      this.$swal.queue([{
        title: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.primary,
        cancelButtonColor: '#8388A5',
        confirmButtonText: 'Use Funds',
        showLoaderOnConfirm: true,
        reverseButtons: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          this.useFundsLoading = true
          return this.$store.dispatch('board/UPDATE_PAYMENT_USE_FUNDS', {
            amount: this.amount,
            boardId: this.boardId,
            service: this.service,
            userId: this.lawyerId,
            problem: this.problem
          })
            .then(res => {
              this.socket.emit('legal-payment', {
                notif: 1,
                notifType: 'Legal Advice Paid',
                email: this.lawyerEmail,
                legalAdvice: this.boardId,
                clientName: this.clientName,
                problem: {
                  _id: this.problem,
                  isAnonymous: this.isAnonymous
                },
                transaction: {
                  amount: this.amount,
                  type: 'Legal Advice Payment'
                }
              })
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Successfully used your available balance for payment.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
              this.success()
            })
            .catch(() => {
              this.$swal.insertQueueStep({
                type: 'error',
                text: 'Something went wrong with the server.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .finally(() => {
              this.useFundsLoading = false
            })
        }
      }])
    },
    success () {
      if (this.imgSrc) {
        this.$emit('successWithDeposit')
        this.imgSrc = ''
        this.fileName = ''
      } else {
        this.$emit('successWithFunds')
      }
      this.show = false
    },
    resetImage () {
      this.$refs.addImageForm.reset()
      this.$refs.image.value = ''
    }
  },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    wallet () {
      return this.$store.getters['transactions/wallet']
    }
  },
  mounted () {
    this.$store.dispatch('transactions/GET_TRANSACTIONS', { page: 1 })
  }
}
</script>

