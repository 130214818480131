<template>
  <v-dialog persistent v-model="show" width="500px">
    <v-sheet>
      <v-container grid-list-md pb-2>
        <p class="title">Create Dispute</p>
        <v-form ref="disputeForm">
          <v-layout row wrap>
            <v-textarea
              outlined
              auto-grow
              label="Please explain in detail your reasons for the dispute."
              counter="1000"
              v-model="message"
              :rules="messageRules"
            ></v-textarea>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions class="lightBlack px-3 py-2">
        <v-btn
          text
          @click="show = ''"
        >Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          :loading="btnLoading"
          color="primary"
          @click="createDispute"
        >Create Dispute</v-btn>
      </v-card-actions>
    </v-sheet>
  </v-dialog>
</template>

<script>
import io from 'socket.io-client'
import DisputeService from '../../services/DisputeService'

export default {
  props: [
    'visible',
    'lawyerId',
    'clientId',
    'problemId',
    'boardId',
    'lawyerEmail',
    'problemTitle',
    'isAnonymous'
  ],
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      btnLoading: false,
      messageRules: [
        (v) => !!v || 'Please enter your message.'
      ],
      message: ''
    }
  },
  methods: {
    createDispute () {
      if (this.$refs.disputeForm.validate()) {
        this.btnLoading = true

        DisputeService.createDispute({
          problem: this.problemId,
          defendant: this.lawyerId,
          board: this.boardId,
          complainantMessage: this.message
        })
          .then((res) => {
            if (res.status === 201) {
              this.socket.emit('dispute-lawyer', {
                notif: 1,
                notifType: 'Disputed Lawyer',
                email: this.lawyerEmail,
                user: this.$store.getters['user/user'].user.fullName,
                imageUrl: this.$store.getters['user/user'].user.imageUrl,
                disputeId: res.data._id,
                problem: {
                  _id: this.problemId,
                  isAnonymous: this.isAnonymous,
                  title: this.problemTitle
                }
              })
              this.socket.emit('dispute', {
                type: 'Dispute',
                count: 1
              })
              this.$refs.disputeForm.reset()
              this.show = ''
              this.$swal({
                type: 'success',
                text: 'Successfully created a dispute to this task.',
                onOpen: () => {
                  document.activeElement.blur()
                }
              })
              this.btnLoading = false
              this.$router.push({ path: `/disputes/${res.data._id}` })
            }
          })
          .catch(() => {
            this.btnLoading = false
          })
      }
    }
  },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$refs.disputeForm.reset()
          this.$emit('close')
        }
      }
    }
  }
}
</script>

<style>

</style>
