<template>
  <v-dialog persistent v-model="show" max-width="500px">
    <v-sheet>
      <v-card-actions class="px-4 pt-4">
        <v-btn block depressed :class="{ 'primary': !uploadReceipt }" @click="uploadReceipt = false, removeImage()">Use Funds</v-btn>
        <v-btn block depressed :class="{ 'primary': uploadReceipt }" @click="uploadReceipt = true">Upload Payment</v-btn>
      </v-card-actions>
      <v-layout v-if="uploadReceipt" row wrap align-center pa-4>
        <v-flex xs12>
          <input
            ref="image"
            type="file"
            name="image"
            accept="image/*"
            style="display: none;"
            @change="setImage"
          />
          <VueCropper
            v-show="false"
            ref="cropper"
            :dragMode="'none'"
            :viewMode="1"
            :autoCrop="false"
            :zoomOnWheel="false"
            :background="false"
            :src="imgSrc"
          ></VueCropper>
          <v-form lazy-validation ref="addImageForm">
            <v-text-field
              :label="imgSrc ? 'Click here to replace the image' : 'Click here to upload the image'"
              :rules="imageRule"
              outline
              hide-details
              append-icon='attach_file'
              v-model="fileName"
              @click='launchFilePicker'
            ></v-text-field>
          </v-form>
        </v-flex>
      </v-layout>
      <v-layout v-else row wrap text-xs-center pa-4>
        <v-flex xs5>
          <p class="mb-1">My Wallet</p>
          <span class="primary--text subheading font-weight-medium">{{ wallet | toLocaleString }}</span>
        </v-flex>
        <v-flex xs2>
          <v-divider vertical></v-divider>
        </v-flex>
        <v-flex xs5>
          <p class="mb-1">To Pay</p>
          <span class="error--text subheading font-weight-medium">{{ amount | toLocaleString }}</span>
        </v-flex>
      </v-layout>
      <v-card-actions class="lightBlack px-4 py-2">
        <v-btn @click="close" text>Cancel</v-btn>
        <v-spacer />
        <v-btn
          v-if="uploadReceipt"
          depressed
          color="primary"
          :disabled="!hasImage"
          :loading="isUploading"
          @click="changePayment"
        >Edit Payment</v-btn>
        <v-btn
          v-else
          depressed
          color="primary"
          :loading="isUploading"
          :disabled="insufficient"
          @click="useFunds"
        >Use Funds</v-btn>
      </v-card-actions>
    </v-sheet>
  </v-dialog>
</template>

<script>
import toLocaleString from '@/mixins/toLocaleStringMixin'

export default {
  mixins: [
    toLocaleString
  ],
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    amount: {
      type: Number,
      required: true
    },
    boardId: {
      type: String,
      required: true
    },
    paymentId: {
      type: String,
      required: true
    },
    cloudinaryPublicId: {
      type: String
    },
    service: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      imgSrc: '',
      file: '',
      fileName: '',
      useFundsLoading: false,
      isUploading: false,
      uploadReceipt: true,
      hasImage: false,
      imageRule: [
        (v) => !!v || 'Please enter an image.'
      ]
    }
  },
  methods: {
    launchFilePicker () {
      this.$refs.image.click()
    },
    setImage (e) {
      if (e.target.files[0]) {
        this.file = e.target.files[0]
      }

      if (this.file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        this.imgSrc = ''
        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          this.fileName = e.target.files[0].name
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(this.file)
        this.hasImage = true
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    changePayment () {
      if (this.$refs.addImageForm.validate()) {
        this.isUploading = true

        this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
          const formData = new FormData()
          formData.append('receipt', blob)
          formData.append('paymentId', this.paymentId)
          formData.append('boardId', this.boardId)
          formData.append('cloudinaryPublicId', this.cloudinaryPublicId)

          this.$store.dispatch('board/UPDATE_PAYMENT', { formData, service: this.service })
            .then(res => {
              this.success()
              this.$swal({
                type: 'success',
                text: 'Image uploaded, please wait for us to verify your payment.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .catch(() => {
              this.$swal({
                type: 'error',
                title: 'Image upload failed',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .finally(() => {
              this.isUploading = false
            })
        }, this.file.type, 0.3)
      }
    },
    useFunds () {
      this.$swal.queue([{
        title: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.primary,
        cancelButtonColor: '#8388A5',
        confirmButtonText: 'Use Funds',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          return this.$store.dispatch('board/UPDATE_PAYMENT_USE_FUNDS', {
            amount: this.amount,
            boardId: this.boardId,
            paymentId: this.paymentId,
            cloudinaryPublicId: this.cloudinaryPublicId,
            service: this.service
          })
            .then(res => {
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Successfully used your available balance for payment.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
              this.success()
            })
            .catch(() => {
              this.$swal.insertQueueStep({
                type: 'error',
                text: 'Something went wrong with the server.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    },
    success () {
      if (this.imgSrc) {
        this.$emit('successWithDeposit')
        this.imgSrc = ''
        this.fileName = ''
      } else {
        this.$emit('successWithFunds')
      }
      this.show = false
    },
    removeImage () {
      // this.$refs.addImageForm.reset()
      this.$refs.image.value = ''
      this.imgSrc = ''
      this.fileName = ''
      this.file = ''
    },
    close () {
      if (this.imgSrc) {
        this.$refs.addImageForm.reset()
        this.$refs.image.value = ''
        this.imgSrc = ''
        this.fileName = ''
        this.file = ''
      }
      this.$emit('close')
    }
  },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    wallet () {
      return this.$store.getters['transactions/wallet']
    },
    insufficient () {
      return this.$store.getters['transactions/wallet'] < this.amount
    }
  },
  mounted () {
    this.$store.dispatch('transactions/GET_TRANSACTIONS', { page: 1 })
  }
}
</script>
